<div>
  <section class="mix">
    <input (click)="inputFile.click()" type="text" readonly placeholder="Seleccione archivo..." [(ngModel)]="fileName"
      [id]="inputId" class="p-inputtext p-component" [class]="className" />
    <a (click)="inputFile.click()" href="javascript:void(0)" class="z-btn z-btn-form auto">{{
      fileName
      ? 'Cambiar
      archivo'
      : 'Elegir archivo'
      }}</a>
  </section>
  <input [accept]="extAllowed" (change)="getFileFromInput($event)" type="file" style="display: none" #inputFile />
</div>