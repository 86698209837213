import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-coberturas-flexibles',
  templateUrl: './coberturas-flexibles.component.html',
  styleUrls: ['./coberturas-flexibles.component.css'],
})
export class CoberturasFlexiblesComponent implements OnInit{
  @Input() planesOriginales: any[] = [];
  @Input() coberturas: any[] = [];
  @Input() deshabilitar: boolean = false;
  @Output() coberturasChange = new EventEmitter<any[]>();
  @Output() recotizar: EventEmitter<void> = new EventEmitter();

  idActual: any;

  ngOnInit(): void {
    if (this.validarGrupoObligatoria())
      this.cotizar();
  }

  seleccionarOpc(
    id: any,
    seleccionado: boolean,
    planId: any,
    idGrupo: any = null,
    multiOp: boolean = true,
    obligatorio: boolean = false
  ) {

    if(this.deshabilitar) return;

    if (!multiOp) {
      this.cotizar();
      return;
    }

    console.log(this.validarBotonGrupoSeleccionado(idGrupo, id, planId))

    if(obligatorio && this.validarBotonGrupoSeleccionado(idGrupo, id, planId)) return;
    

    if (idGrupo) {
      for (let i = 0; i < this.coberturas.length; i++) {
        let cobertura = this.coberturas[i];

        for (let j = 0; j < cobertura.planes.length; j++) {
          let plan = cobertura.planes[j];

          if (plan.id == planId) {
            for (let k = 0; k < plan.opciones.length; k++) {
              let op = plan.opciones[k];
              if (op.idGrupo == idGrupo){
                if(obligatorio && op.id == id) op.seleccionado = true;
                else op.seleccionado = false;
              }
            }
          }
        }
      }
    }

    for (let i = 0; i < this.coberturas.length; i++) {
      let cobertura = this.coberturas[i];

      for (let j = 0; j < cobertura.planes.length; j++) {
        let plan = cobertura.planes[j];

        if (plan.id == planId) {
          for (let k = 0; k < plan.opciones.length; k++) {
            let op = plan.opciones[k];

            if (op.id == id) {
              op.seleccionado = obligatorio ? true : !seleccionado;
              this.idActual = op.id;
            }
          }
        }
      }
    }

    this.cotizar();
  }

  cotizar() {
    this.coberturasChange.emit(this.coberturas);
    this.recotizar.emit();
  }

  validarBotonGrupoSeleccionado(idGrupo: any, id: any, planId: any){
    console.log('idGrupo: '+ idGrupo+ ' id:'+ id + 'planid: '+ planId)

    for (let i = 0; i < this.coberturas.length; i++) {
      let cob = this.coberturas[i];

      for (let j = 0; j < cob.planes.length; j++) {
        let pl = cob.planes[j];

        if (pl.id == planId) {
          for (let k = 0; k < pl.opciones.length; k++) {
            let opcion = pl.opciones[k];

            if (opcion.idGrupo == idGrupo && opcion.id == id ){
              console.log(opcion)
              return  opcion.seleccionado;
            }
          }
        }
      }
    }
  }

  validarGrupoObligatoria(){
    let tarificarInicio = false;

    for (let i = 0; i < this.coberturas.length; i++) {
      let cob = this.coberturas[i];

      for (let j = 0; j < cob.planes.length; j++) {
        let pl = cob.planes[j];

        for (let k = 0; k < pl.opciones.length; k++) {
          let opcion = pl.opciones[k];

          if (opcion.idGrupo && opcion.tipoCobertura =='OBLIGATORIA' && opcion.seleccionado){
            tarificarInicio = true;
          }
        }
      }
    }

    return tarificarInicio;
  }

}
