import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-vinetaEstados',
  template: `
    <div style="margin-top: -3px">
      <span class="me-2" *ngIf="posicion == 'izquierda'"></span>
      <p class="h5">{{ mensaje }}</p>
      <span *ngIf="posicion == 'derecha'"></span>
    </div>
  `,
  styleUrls: ['./vineta-estados-generales.component.scss'],
  host: {
    class: 'z-estados-generales',
  },
  encapsulation: ViewEncapsulation.None,
})
export class VinetaEstadosGeneralesComponent implements OnInit, OnChanges {
  @HostBinding('class') classList: string = '';
  @Input() posicion: string = 'izquierda'; // izquierda, derecha
  @Input() estado: string = 'informacion';
  @Input() mensaje: string = 'Aceptado';
  /***
   *   informacion azul
   *   advertencia amarillo
   *   aprobado verde
   *   muy rechazado rojo
   *   rechazado negro
   */
  constructor() {
    // nada
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.classList = this.posicion + ' ' + this.estado;
  }

  ngOnInit(): void {}
}
