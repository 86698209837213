<p-dialog [(visible)]="showModal" [draggable]="false" [resizable]="false" [modal]="true"
  [dismissableMask]="dismissableMask" [closable]="showCloseBtn" class="z-modal w-800">
  <ng-template pTemplate="header">
    <!-- normal -->
    <h2 class="z-titulo z-center" *ngIf="tipoMensaje == 1">
      <strong>{{ textHeader }}</strong>
    </h2>
    <!-- error -->
    <h2 class="z-titulo z-center" *ngIf="tipoMensaje == 2">
      <figure class="z-iconos w-96">
        <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
      </figure>
      <strong>{{ textHeader }}</strong>
    </h2>
    <!-- espera -->
    <h2 class="z-titulo z-center" *ngIf="tipoMensaje == 3">
      <div class="z-separador h-20"></div>
      <span class="z-loader-html w-96"></span>
      <strong>{{ textHeader }}</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="z-center">
      {{ textContent }}
    </p>

    <div class="z-separador h-50"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="centrar">
      <button *ngIf="showBtnOk" type="button" (click)="handleOk()" class="z-btn z-btn-primary">
        {{ textHandleOk }}
      </button>
    </div>
    <div class="simple" *ngIf="showCloseBtn">
      <a href="#" href="javascript:void(0)" (click)="handleOk()" class="z-btn z-btn-primary">{{ textHandleOk }}</a>
    </div>
  </ng-template>
</p-dialog>