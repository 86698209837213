<div class="card intermedia" *ngIf="!cargando">
  <div class="col-12 col-md-6 col-lg-4 col-xl-12">
    <div class="z-tarjeta-detalle">
      <div class="top">
        <h1 data-title="Solicitud">
          Cotización N° {{ datosPoliza.numeroCotizacion }}
        </h1>
        <span class="z-chip verde" style="background-color: green">Póliza emitida</span>
      </div>
      <div class="content">
        <div class="data">
          <div class="data-module">
            <p>Beneficiario</p>
            <h5>{{ datosPoliza.nombreAsegurado }}</h5>
          </div>
          <div class="data-module">
            <p>Fecha emision</p>
            <h5>{{ datosPoliza.fechaEmision }}</h5>
          </div>
        </div>
        <div class="data">
          <div class="data-module">
            <p>Nº Póliza</p>
            <h5>{{ datosPoliza.numeroPoliza }}</h5>
          </div>
          <div class="data-module">
            <p>Nº Cotizacion</p>
            <h5>{{ datosPoliza.numeroCotizacion }}</h5>
          </div>
        </div>
        <div class="data">
          <div class="data-module">
            <p>Valor cuota Uf</p>
            <h5>{{ datosPoliza.primaMensualUf }}</h5>
          </div>
          <div class="data-module">
            <p>Valor cuota Peso</p>
            <h5>{{ datosPoliza.primaMensualPeso }}</h5>
          </div>
        </div>
      </div>
      <div class="extra">
        <input type="checkbox" id="tarjeta_identificador_2" /><label for="tarjeta_identificador_2" data-on="Cerrar"
          data-off="Ver más información"></label>
        <div class="expandable-content pt-3">
          <div class="offset-8 col-md-4">
            <a (click)="descargarPoliza()" [disableLink]="descargandoPoliza" href="javascript:void(0)"
              class="z-btn z-btn-download">
              <figure>
                <svg *ngIf="!descargandoPoliza; else loadPropuesta" width="24" height="26" viewBox="0 0 24 26"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.5243 16.3054C19.0125 15.3549 16.5994 14.6968 14.4788 14.5505C14.2594 14.3312 14.1131 14.1119 13.8938 13.8925C12.0657 11.8452 11.3344 8.84736 11.115 7.23877C11.115 6.87318 11.0419 6.65385 11.0419 6.43449C11.0419 6.28826 10.9688 5.92266 10.6032 5.92266C10.4569 5.92266 10.3838 5.99578 10.3107 6.0689C10.1644 6.21514 10.2375 6.36138 10.2375 6.58074C10.2375 6.80009 10.3106 7.09254 10.3838 7.38501C10.53 8.99361 10.6032 12.0645 9.36004 14.5505C9.21379 14.7699 9.06754 15.0624 8.99442 15.2817C6.65443 15.9398 4.75317 17.1828 4.53379 18.1333C4.46067 18.4989 4.53383 18.8645 4.7532 19.0839C5.0457 19.3763 5.4113 19.5226 5.77692 19.5226C6.87379 19.5226 8.11693 18.2796 9.5063 15.8667C10.0913 15.7205 10.7494 15.5742 11.3344 15.5011C11.5538 15.5011 11.9194 15.428 12.1388 15.428C12.7969 15.3549 13.455 15.2817 14.1862 15.2817C15.8681 17.0366 17.2575 17.914 18.2812 17.914C18.8662 17.914 19.305 17.6215 19.5975 17.1828C19.6706 16.9635 19.7437 16.5979 19.5243 16.3054ZM5.70381 18.7914C5.55756 18.7914 5.41129 18.7183 5.26504 18.572C5.19192 18.4989 5.19193 18.4258 5.19193 18.3527C5.33818 17.8409 6.50818 16.9635 8.26317 16.3054C7.31255 17.8409 6.36193 18.7914 5.70381 18.7914ZM11.9194 14.6968C11.7 14.6968 11.3344 14.7699 11.115 14.7699C10.7494 14.8431 10.3107 14.843 9.87192 14.9893C9.87192 14.9893 9.8719 14.9161 9.94503 14.9161C10.53 13.7463 10.8956 12.2839 11.0419 10.6022C11.6269 12.1377 12.3581 13.4538 13.2356 14.4774L13.3087 14.5505C12.87 14.5505 12.4313 14.6237 11.9194 14.6968ZM18.7931 16.8903C18.6468 17.1097 18.5006 17.2559 18.2081 17.2559C17.55 17.2559 16.5994 16.671 15.3563 15.5011C17.1844 15.7205 18.5737 16.3054 18.7931 16.7441C18.8662 16.7441 18.8662 16.8172 18.7931 16.8903Z">
                  </path>
                  <path
                    d="M16.0143 0H3.36373C1.5356 0 0 1.53545 0 3.3634V22.4471C0 24.2751 1.5356 25.8105 3.36373 25.8105H20.5481C22.3762 25.8105 23.9118 24.2751 23.9118 22.4471V7.9698L16.0143 0ZM20.6212 23.6901H3.43683C2.70558 23.6901 2.12059 23.1052 2.12059 22.374V3.3634C2.12059 2.63222 2.70558 2.04729 3.43683 2.04729H15.2831V6.43435C15.2831 7.75047 16.3068 8.77412 17.6231 8.77412H21.9375L22.0106 22.4471C21.9374 23.1052 21.3524 23.6901 20.6212 23.6901Z">
                  </path>
                </svg>
                <ng-template #loadPropuesta>
                  <span style="opacity: 0.3" class="z-loader-html w-24"></span>
                </ng-template>
              </figure>
              <span>Certificado de póliza</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>