<p-dialog [visible]="true" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
  [closable]="false" [closeOnEscape]="false" class="z-modal w-500">
  <ng-template pTemplate="header">
    <h3 class="z-center z-titulo">
      <div class="z-separador h-20"></div>
      <span class="z-loader-html w-96"></span>

      <strong>Cargando los datos de la cotización...</strong>
    </h3>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="z-titulo z-center">Por favor, espere unos segundos</p>

    <div class="z-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer"> </ng-template>
</p-dialog>