<div *ngIf="resumen$ | async as r">
  <section class="titular">
    <figure>
      <img src="assets/img/svg/circle-sum.svg" alt="Circle sum" />
    </figure>
    <figcaption>
      <h2>
        Cotización
        <span *ngIf="r.numerocotizacion != ''">#{{ r.numerocotizacion }}</span>
      </h2>
      <h3></h3>
    </figcaption>
  </section>
  <section class="deploy">
    <article class="data completed" *ngFor="let item of r.resumen; let i = index">
      <span> </span>
      <div class="data-item">
        <button class="data-interaction" [ngClass]="{ active: item.expanded }" (click)="activeDataItem(i)">
          {{ item.title }}
        </button>
        <div class="extra" *ngIf="item.expanded">
          <div class="data-module z-simple-data" *ngFor="let extra of item.items">
            <h3 class="mb-1">{{ extra.title }}</h3>
            <h4 [class.mb-2]="extra?.subDescription != ''"
              style="font-weight: bold; font-family: 'overpassbold', sans-serif;" *ngIf="extra.description != ''">{{
              extra.description }}</h4>
            <h4 style="font-weight: bold; font-family: 'overpassbold', sans-serif;" *ngIf="extra?.subDescription != ''">
              {{
              extra.subDescription }}</h4>
          </div>
        </div>
      </div>
    </article>

    <article class="data current">
      <span></span>
      <div class="data-item">
        <h2>{{ r.nameCurrentProcess }}</h2>
      </div>
    </article>
  </section>
  <div class="z-separador h10"></div>
</div>