<div class="row">
  <div class="col-12 col-lg-7">
    <div class="z-bloque">
      <div class="z-separador" [ngClass]="device != 'desktop' ? 'h-0' : 'h-100'"></div>
      <section class="z-informacion-lateral claro">
        <figure>
          <img src="/assets/img/mascota.png" alt="Perro Mascota Zenit" />
        </figure>
        <h1 class="z-titulo z-especial">
          Suscripción y pagos Zenit Seguros
          <span>Contrata y Cotiza en Línea</span>
        </h1>
      </section>
    </div>
  </div>
  <div class="col-12 col-lg-5">
    <div class="z-separador h-30" *ngIf="device == 'desktop'"></div>
    <div class="z-bloque background blanco r-4 no-padding">
      <article>
        <h4 class="z-titulo naranja z-up z-negrita">
          <strong>TARJETAS INSCRITAS</strong>
        </h4>
        <p>Selecciona una o inscribe una nueva tarjeta</p>
        <div class="z-separador h-30 lineal th-1"></div>
        <form>
          <div class="row">
            <div class="col-10 col-md-11 col-lg-10">
              <p-radioButton name="Op1" value="tarjeta_1" [(ngModel)]="opciones_tarjetas" inputId="radioButton_1"
                label="1111 2222 3333 4444 5555"></p-radioButton>
            </div>
            <div class="col">
              <figure class="z-iconos plain">
                <img src="assets/img/svg/tarjeta-mc.svg" alt="Tarjeta Master Card" />
              </figure>
            </div>
            <div class="col-12">
              <div class="z-separador h-20"></div>
            </div>
            <div class="col-10 col-md-11 col-lg-10">
              <p-radioButton name="Op2" value="tarjeta_2" [(ngModel)]="opciones_tarjetas" inputId="radioButton_2"
                label="1111 2222 3333 4444 5555"></p-radioButton>
            </div>
            <div class="col">
              <figure class="z-iconos plain">
                <img src="assets/img/svg/tarjeta-visa.svg" alt="Tarjeta Master Card" />
              </figure>
            </div>
            <div class="col-12">
              <div class="z-separador h-20"></div>
            </div>
            <div class="col-10 col-md-11 col-lg-10">
              <p-radioButton name="Op3" value="tarjeta_3" [(ngModel)]="opciones_tarjetas" inputId="radioButton_3"
                label="1111 2222 3333 4444 5555"></p-radioButton>
            </div>
            <div class="col">
              <figure class="z-iconos plain">
                <img src="assets/img/svg/tarjeta-visa.svg" alt="Tarjeta Master Card" />
              </figure>
            </div>
            <div class="col-12">
              <div class="z-separador h-20"></div>
            </div>
            <div class="col-10 col-md-11 col-lg-10">
              <p-radioButton name="Op4" value="tarjeta_4" [(ngModel)]="opciones_tarjetas" inputId="radioButton_4"
                label="1111 2222 3333 4444 5555"></p-radioButton>
            </div>
            <div class="col">
              <figure class="z-iconos plain">
                <img src="assets/img/svg/tarjeta-mc.svg" alt="Tarjeta Master Card" />
              </figure>
            </div>
            <div class="col-12">
              <div class="z-separador h-20"></div>
            </div>
          </div>
        </form>
        <div class="z-bloque center-objects no-padding">
          <a href="#" class="z-btn z-btn-primary min w-300">Suscribir Selección</a>
        </div>
      </article>
      <article class="background naranja-20 center-objects">
        <a href="#" class="z-btn z-btn-secondary min w-300">Agregar más tarjetas</a>
      </article>
    </div>
  </div>
</div>