<p-dialog [(visible)]="mostrarModal" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
  class="z-modal">
  <ng-template pTemplate="header">
    <h2 class="z-center z-titulo">
      <strong>{{ tituloModal }}</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe *ngIf="urlIframe" [src]="urlIframe" height="700px" width="875px" allowfullscreen="true"
      title="Iframe de encuesta"></iframe>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end" style="padding-left: 34px; padding-right: 34px">
      <a id="z-btn-contactenos-header" (click)="cerrarModal()" target="_blank" class="z-btn z-btn-secondary icon">
        <span> Cerrar </span>
      </a>
    </div>
  </ng-template>
</p-dialog>