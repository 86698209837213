export const environment = {
  production: true,
  cryptoKey: '%D*F-JaNdRgUkXp2s5v8y/B?E(H+KbPe',
  cryptoKeyContinuarCotizacion: 'UkXp2s5v8y/B?E(H+MbQeThVmYq3t6w9',
  CantidadCuotas: 12,
  IdApp: 39,
  UrlBase: 'https://apigwp.bciseguros.cl:8443/Apiventaasistidazenit',
  recaptchaActions: {
    Step1: 'datos_generales_inicio',
    Step2: 'datos_generales_cotizacion',
    Step3: 'datos_generales_datos_contratante',
  },
  recaptchaLabels: {
    ErrorTitle: 'Error de verificación',
    ErrorSubtitle: 'Posible actividad sospechosa detectada',
  },
};
