<div class="row">
  <div class="col" *ngIf="isMobile; else desktop">
    <ng-container *ngIf="getActiveStep$ | async as currentStep">
      <div class="z-pasos-indice" [class]="currentStep.progressValue === 100 ? 'completed' : 'active'">
        <span class="index">{{ currentStep.index }}</span>
        <h2 class="z-contenido z-titulo">{{ currentStep.title }}</h2>
        <span class="pasos">
          Paso {{ currentStep.index }} de {{ (steps$ | async)?.length }}</span>
        <p-progressBar [value]="currentStep.progressValue" [showValue]="false"></p-progressBar>
      </div>
    </ng-container>
  </div>
  <ng-template #desktop>
    <div *ngFor="let step of steps$ | async; let i = index" class="col z-pasos-indice"
      [ngClass]="{ completed: step.completed, active: step.active }">
      <span class="index">{{ i + 1 }}</span>
      <h2 class="z-contenido z-titulo">{{ step.title }}</h2>
      <p-progressBar [value]="step.progressValue" [showValue]="false"></p-progressBar>
    </div>
  </ng-template>
</div>