import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({ selector: '[dropdownLoading]' })
export class DropdownLoadingDirective implements OnChanges {
  @Input()
  dropdownLoading: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownLoading) {
      if (this.dropdownLoading) {
        const element = this.el.nativeElement as HTMLElement;
        const dropdownArrow = element.querySelector(
          '.p-dropdown .p-dropdown-trigger'
        );

        if (dropdownArrow) {
          dropdownArrow.classList.add('z-loader-html');
          dropdownArrow.classList.add('w-24');
          dropdownArrow.setAttribute(
            'style',
            'top: 10px; right: 10px; background: none;'
          );
        }
      } else {
        const element = this.el.nativeElement as HTMLElement;
        const dropdownArrow = element.querySelector(
          '.p-dropdown .p-dropdown-trigger'
        );

        if (dropdownArrow) {
          dropdownArrow.classList.remove('z-loader-html');
          dropdownArrow.classList.remove('w-24');
          dropdownArrow.setAttribute(
            'style',
            'background: url(icon-arrow-down.svg) center center no-repeat; background-size: 16px auto;'
          );
        }
      }
    }
  }
}
