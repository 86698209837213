<div>
  <h5 class="z-titulo mb-3">
  </h5>
  <div class="row">
    <div class="col-md-11" style="margin-bottom: 100px">
      <div class="row">
        <div class="col-md-4">
          <div class="z-simple-data">
            <p class="mb-2" style="font-weight: bold">Número de propuesta</p>
            <p>{{ numeroPropuesta }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="z-simple-data">
            <p class="mb-2" style="font-weight: bold">Nombre del cliente</p>
            <p>{{ nombreCliente }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="z-simple-data">
            <p class="mb-2" style="font-weight: bold">Marca Modelo</p>
            <p>{{ marcaModelo }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!ocultarMedioEnvio">
        <div class="z-separador h-10 th-1 lineal mb-5"></div>
        <div class="row mb-4">
          <div class="col-md-4">
            <a (click)="seleccionarMetodoEnvio(medioEnvioEnum.EMAIL)" href="javascript:void(0)"
              class="z-selector w-100p" [class.visited]="medioEnvio === medioEnvioEnum.EMAIL">
              <figure>
                <img style="margin-top: -3px; width: 35px" src="assets/img/svg/icon-menu-mail.svg"
                  alt="método de envío" />
              </figure>
              <span class="label"> E-mail </span><span class="arrow"></span>
            </a>
          </div>
        </div>

        <div class="row" [style.display]="medioEnvio ? 'block' : 'none'">
          <div class="col-md-8">
            <label for="envio" class="z-forms">
              <input #inputValueRef [class.is-invalid]="invalidInput" [formControl]="inputValue" [type]="inputType"
                [placeholder]="placeholderText" id="envio" [(ngModel)]="textInput" class="p-inputtext p-component" />
              <p *ngIf="invalidInput" class="z-caption z-error">
                {{ textError }}
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="z-separador h-10 th-1 lineal"></div>

    <div class="row">
      <div class="col-md-6">
        <div class="z-accionables">
          <a (click)="descargarPropuesta()" href="javascript:void(0)">
            <figure class="z-iconos block w-48 ng-star-inserted">
              <span *ngIf="descargandoPropuesta" class="z-loader-html w-24" style="top: 10px"></span>
              <img *ngIf="!descargandoPropuesta" alt="icono" src="assets/img/svg/icon-color-pdf.svg" />
            </figure>
            <span>
              {{
              descargandoPropuesta
              ? 'Descargando propuesta...'
              : 'Descargar propuesta'
              }} </span><svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.30271 0.413446C1.77576 -0.137815 0.922157 -0.137815 0.39521 0.413446C-0.131737 0.964708 -0.131737 1.8577 0.39521 2.40896L4.21021 6.4L0.39521 10.391C-0.131737 10.9423 -0.131737 11.8353 0.39521 12.3866C0.922157 12.9378 1.77576 12.9378 2.30271 12.3866L7.07146 7.39776C7.5984 6.8465 7.5984 5.9535 7.07146 5.40224L2.30271 0.413446Z">
              </path>
            </svg>
          </a>
        </div>
      </div>
      <div class="col-md-6" style="text-align: end; align-self: center">
        <a (click)="emisionDirecta()" style="margin-left: 0; margin-right: 0" href="javascript:void(0)"
          [disableLink]="enviandoLink" class="z-btn z-btn-primary">{{ textBtnContinuar }}</a>
        <p></p>
      </div>
    </div>
  </div>

  <p-dialog [visible]="volerAtras" (visibleChange)="mostrarAdvertenciaEd(false)" [draggable]="false" [resizable]="false"
    [modal]="true" [dismissableMask]="true" [closable]="true" [closeOnEscape]="true" class="z-modal w-500">
    <ng-template pTemplate="header">
      <h3 class="z-center z-titulo">
        <figure class="z-iconos w-96">
          <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
        </figure>
        <strong>Advertencia</strong>
      </h3>
    </ng-template>
    <ng-template pTemplate="content">
      <p class="z-center">
        No ha emitido la póliza, ¿Esta seguro que quiere volver a generar una
        nueva cotización?
      </p>

      <div class="z-separador h-10"></div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="simple">
        <a href="#" href="javascript:void(0)" (click)="atrasEd()" class="z-btn z-btn-primary">Volver atras</a>
      </div>
    </ng-template>
  </p-dialog>
</div>