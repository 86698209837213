<div class="row">
  <div class="col-12 col-lg-5">
    <div class="z-bloque">
      <div class="z-separador" [ngClass]="device != 'desktop' ? 'h-0' : 'h-100'"></div>
      <section class="z-informacion-lateral claro">
        <figure>
          <img src="/assets/img/mascota.png" alt="Perro Mascota Zenit" />
        </figure>
        <h1 class="z-titulo z-especial">
          Suscripción de
          <span>Pago Automático</span>
        </h1>
      </section>
    </div>
  </div>
  <div class="col-12 col-lg-7">
    <div class="z-separador h-30" *ngIf="device == 'desktop'"></div>
    <div class="z-bloque background blanco r-4">
      <h4 class="z-titulo naranja z-up z-negrita">
        <strong>Datos de su suscripción</strong>
      </h4>
      <div class="z-separador h-10 lineal th-1"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="z-simple-data">
            <h3>Nombre</h3>
            <p>Juan Carlos Cabello González</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="z-simple-data">
            <h3>Prima Mensual</h3>
            <p>UF 12,12</p>
          </div>
        </div>
        <!-- <div class="col-12 col-md-3">
                    <div class="z-simple-data invertido">
                       
                        <p>
                          
                Prima Mensual
                        </p>
                        <h3>UF 12,12</h3>
                    </div>
                </div> -->
        <div class="col-6 col-md-3">
          <div class="z-simple-data naranja">
            <h3>Prima mensual a pagar *</h3>
            <p>$123.123</p>
          </div>
        </div>
      </div>
      <div class="z-separador h-10"></div>
      <small>* Monto UF al día de hoy $35.123,12</small>
    </div>
    <div class="z-bloque background naranja-20 r-4">
      <h2>
        <div class="z-forms no-margin">
          <div class="z-separador h-10"></div>
          <p-checkbox inputId="Grupo_1" name="Grupo_1" value="dato_numero_1" [(ngModel)]="tipo_checkbox" inputId="gru1"
            label="Acepto términos y condiciones"></p-checkbox>
        </div>
      </h2>

      <a class="z-btn z-btn-link" (click)="show_modal_2()">Revisar documento</a>
    </div>
    <div class="z-bloque background blanco r-4">
      <details class="z-selector w-100p grande sombra expandible" open>
        <summary class="cta">
          <figure>
            <img src="assets/img/svg/logo-tarjeta.svg" alt="image Selector" />
          </figure>
          <span class="label"> Pagar online con tarjeta </span>
          <span class="arrow"> </span>
        </summary>
        <section class="data">
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-klap.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quas
            </p>
            <a (click)="show_modal_3()" class="z-btn z-btn-link">Ver Ejemplo</a>
          </div>
          <div class="z-separador h-20 lineal th-1"></div>
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-patpass.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quasi qui quia .
            </p>
            <a (click)="show_modal_3()" class="z-btn z-btn-link">Ver Ejemplo</a>
          </div>
        </section>
      </details>

      <details class="z-selector w-100p grande sombra expandible">
        <summary class="cta">
          <figure>
            <img src="assets/img/svg/logo-debito.svg" alt="image Selector" />
          </figure>
          <span class="label"> Pagar online con débito </span>
          <span class="arrow"> </span>
        </summary>
        <section class="data">
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-klap.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quas
            </p>
            <a (click)="show_modal_3()" class="z-btn z-btn-link">Ver Ejemplo</a>
          </div>
          <div class="z-separador h-20 lineal th-1"></div>
          <div class="z-bloque">
            <figure>
              <img src="/assets/img/logo-patpass.jpg" alt="Sistema Klap" />
            </figure>
            <h5 class="z-titulo">Sistema Klap</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              laudantium modi, doloribus, dolore sequi nihil quod maxime, vel
              quasi qui quia .
            </p>
            <a (click)="show_modal_3()" class="z-btn z-btn-link">Ver Ejemplo</a>
          </div>
        </section>
      </details>
    </div>
  </div>
</div>

<p-dialog [(visible)]="modal_1" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
  class="z-modal w-500">
  <ng-template pTemplate="header">
    <h2 class="z-center z-titulo z-up">
      <figure class="z-iconos w-96">
        <img src="assets/img/svg/icon-ilus-ko.svg" alt="Icono ilustracion KO" />
      </figure>
      <strong>Ocurrió un error inesperado</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="z-center">Inténtelo más tarde</p>

    <div class="z-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="simple">
      <a id="z-btn-contactenos-header" href="#" target="_blank" class="z-btn z-btn-secondary icon">
        <figure>
          <img src="/assets/img/svg/icon-arrow-prev.svg" alt="Ícono atrás" />
        </figure>
        <span> Volver </span>
      </a>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="modal_2" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
  class="z-modal w-700">
  <ng-template pTemplate="header">
    <h2 class="z-titulo z-center">
      <strong>Aceptación Términos y condiciones</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="z-separador h-20"></div>
    <h4 class="z-titulo">
      <strong>Lorem ipsum dolor sit amet consectetur.</strong>
    </h4>
    <div class="z-separador h-10"></div>
    <p>
      Aliquam rhoncus felis fermentum consequat faucibus egestas. Est risus
      pharetra ac fermentum. Leo in vulputate vestibulum aliquet volutpat arcu
      sed. Ac nunc scelerisque nulla aliquam in gravida accumsan. Sed pharetra
      ipsum vitae sit sollicitudin morbi. Ornare aliquam porttitor vel eu metus
      ligula dictum eget elementum. Sit sed at feugiat sit euismod viverra. Id
      turpis ultrices ut bibendum sed venenatis maecenas. Lorem nec mauris
      malesuada risus praesent. Rhoncus commodo proin odio massa odio. Phasellus
      luctus tincidunt tempor bibendum. Convallis sit commodo sed donec sed.
      Vulputate volutpat id nullam risus ut lacus tincidunt. Adipiscing cursus
      erat ac ac in viverra facilisis. Sed aliquet in gravida velit dictum.
      Facilisi imperdiet volutpat suspendisse morbi turpis. Lectus leo sit
      malesuada praesent. Non fusce tellus id congue iaculis eu sem. Donec
      pulvinar egestas aliquet consequat non id maecenas. Massa penatibus nibh
      nec purus quis molestie. Lectus volutpat felis pretium ornare pulvinar
      elementum.
    </p>

    <div class="z-separador h-30"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="extremos">
      <a id="z-btn-contactenos-header" (click)="hide_modal_2()" target="_blank" class="z-btn z-btn-secondary icon">
        <figure>
          <img src="/assets/img/svg/icon-arrow-prev.svg" alt="Ícono atrás" />
        </figure>
        <span> Volver </span>
      </a>
      <a (click)="hide_modal_2()" class="z-btn z-btn-primary">Aceptar</a>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="modal_3" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
  class="z-modal w-700">
  <ng-template pTemplate="header">
    <h2 class="z-titulo z-center">
      <strong>Ejemplo Tarjetas</strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="z-separador h-20"></div>
    <h4 class="z-titulo z-center">
      La tarjeta debe tener enfrente el logo Visa o Mastercard, de lo contrario
      no permitirá la suscripción
    </h4>
    <div class="z-separador h-10"></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <figure class="z-imagen">
          <img src="/assets/img/tarjeta-1.jpg" alt="Tarjeta Visa" />
        </figure>
      </div>
      <div class="col-12 col-sm-6">
        <figure class="z-imagen">
          <img src="/assets/img/tarjeta-2.jpg" alt="Tarjeta Masercard" />
        </figure>
      </div>
    </div>

    <div class="z-separador h-10"></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="simple">
      <a (click)="hide_modal_3()" class="z-btn z-btn-primary">Entendido</a>
    </div>
  </ng-template>
</p-dialog>